/**
 * @prettier
 */

import { CampaignsFilterSearch } from './components/campaigns_filter_head/campaigns_filter_search'
import { CampaignsFilterSelect } from './components/campaigns_filter_head/campaigns_filter_select'
import { CampaignsFilterMainInfoCell } from './components/campaigns_filter_main_info_cell'
import { CampaignsFilterHeadCell } from './components/campaigns_filter_head/campaigns_filter_head_cell'
import { CampaignsFilterCheckboxCell } from './components/campaigns_filter_checkbox_cell'
import { CampaignsFilterUpdatesCell } from './components/campaigns_filter_updates_cell'
import { CampaignsFilerOffersCell } from './components/campaigns_filter_offers_cell'
import {
  Empty,
  Head,
  Spoiler,
  Row,
  Block,
  FilteredHiddenCampaignsNote,
} from './components'
import { CampaignsFilterShowMore } from './components/campaigns_filter_show_more'

export class CampaignsFilter extends React.PureComponent {
  static propTypes = {}

  render() {
    const { children } = this.props

    return <div>{children}</div>
  }
}

CampaignsFilter.HeadCell = CampaignsFilterHeadCell
CampaignsFilter.Search = CampaignsFilterSearch
CampaignsFilter.Select = CampaignsFilterSelect
CampaignsFilter.CheckBoxCell = CampaignsFilterCheckboxCell
CampaignsFilter.MainInfoCell = CampaignsFilterMainInfoCell
CampaignsFilter.UpdatesCell = CampaignsFilterUpdatesCell
CampaignsFilter.OffersCell = CampaignsFilerOffersCell
CampaignsFilter.Spoiler = Spoiler
CampaignsFilter.Row = Row
CampaignsFilter.Block = Block
CampaignsFilter.Empty = Empty
CampaignsFilter.FilteredHiddenCampaignsNote = FilteredHiddenCampaignsNote
CampaignsFilter.Head = Head
CampaignsFilter.ShowMore = CampaignsFilterShowMore
