/**
 * @prettier
 */

import { UIIcon } from 'talkable-ui-kit'
import pluralize from 'pluralize'

import { CAMPAIGN_STATUSES_ORDER_WITH_KEYS } from 'utils/constants'
import { CampaignsFilter } from 'components/shared/campaigns_filter'
import { CampaignFilterCampaignStaticWidget } from 'components/shared/campaigns_filter/components/campaigns_filter_static_widget'

export class CampaignsTip extends React.PureComponent {
  static defaultProps = {
    campaigns: [],
    label: 'Used in',
  }

  constructor(props) {
    super(props)
    this.tooltipRef = React.createRef()
  }

  componentDidMount() {
    if (window.location.hash === `#${this.props.id}/info`) window.location.hash = ''
    const tooltip = this.tooltipRef.current

    tooltip && tooltip.addEventListener('mouseover', this.trackTooltipHover)
  }

  trackTooltipHover = () => {
    Utils.trackGAEvent('A/B Tests', 'campaign tooltip hover')
  }

  toggle = e => {
    e.preventDefault()
    const scrollY = window.scrollY

    window.location.hash = e.target.hash === window.location.hash ? '' : e.target.hash
    window.scrollTo(0, scrollY)
  }

  render() {
    const { id, campaigns, label } = this.props
    const className = ['campaignstip-wrapper', this.props.className].join(' ')

    return (
      <div className={className}>
        <div className="campaignstip-trigger">
          <UIIcon name="circles" />
          {label}&nbsp;
          <a href={`#${id}/info`} className="link" onClick={this.toggle}>
            {campaigns.length} {pluralize('campaign', campaigns.length)}
          </a>
          <span
            ref={this.tooltipRef}
            data-toggle="tooltip"
            data-container="body"
            className="mls"
            title="Includes all campaigns used in the A/B test, even though they may not be in rotation as of today"
          >
            <span className="icon icon-question" />
          </span>
        </div>
        <div className="campaignstip" id={`${id}/info`} aria-hidden="true">
          <a className="modal-close" href="#" aria-hidden="true" onClick={this.toggle} />
          <h3>
            A/B Test Used in {campaigns.length} {pluralize('campaign', campaigns.length)}
          </h3>
          <ul className="campaignfilter-list">
            {_.sortBy(campaigns, c => CAMPAIGN_STATUSES_ORDER_WITH_KEYS[c.status]).map(
              campaign => (
                <CampaignsFilter key={campaign.id}>
                  <CampaignsFilter.Row>
                    <CampaignsFilter.CheckBoxCell checked={false} disabled={true} />
                    <CampaignsFilter.MainInfoCell
                      campaign={campaign}
                      siteCachedSlug={Site.cached_slug}
                    >
                      <CampaignFilterCampaignStaticWidget
                        campaign={campaign}
                        siteCachedSlug={Site.cached_slug}
                      />
                    </CampaignsFilter.MainInfoCell>
                  </CampaignsFilter.Row>
                </CampaignsFilter>
              ),
            )}
          </ul>
          <div className="campaignstip-ear" />
        </div>
      </div>
    )
  }
}
