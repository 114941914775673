/**
 * @prettier
 */

import { H5 } from 'talkable-ui-kit'

import { Cell } from '../index'

export class CampaignsFilterHeadCell extends React.PureComponent {
  static propTypes = {
    width: PT.string,
    stretch: PT.bool,
  }

  static defaultProps = {
    width: null,
    stretch: false,
  }

  render() {
    const { width, stretch, children } = this.props

    return (
      <Cell width={width} stretch={stretch}>
        <H5>{children}</H5>
      </Cell>
    )
  }
}
