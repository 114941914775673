/**
 * @prettier
 */
import { BodyD, BodyS, Theme } from 'talkable-ui-kit'

import { Cell } from './index'

export class CampaignsFilterUpdatesCell extends React.PureComponent {
  static propTypes = {
    width: PT.string,
  }

  static defaultProps = {
    width: '220px',
  }

  render() {
    const {
      campaign: {
        latest_change: { formattedCreatedAt, email },
      },
      width,
    } = this.props

    return (
      <Cell width={width}>
        <BodyS color={Theme.color.black100} className="mbx">
          {formattedCreatedAt}
        </BodyS>
        <BodyD>{email}</BodyD>
      </Cell>
    )
  }
}
