/**
 * @prettier
 */

import { UIInputText } from 'talkable-ui-kit'

export class CampaignsFilterSearch extends React.PureComponent {
  static propsTypes = {
    value: PT.string.isRequired,
    onChange: PT.func.isRequired,
  }

  render() {
    const { value, onChange } = this.props

    return (
      <div className="flex-grow-1" data-testid="ac-campaign-filter-search-block">
        <UIInputText
          name="query"
          value={value}
          onChange={_.debounce(onChange, 300)}
          placeholder="Search by campaign ID, name or status"
          icon={{
            name: 'searchLite',
            width: 22,
          }}
        />
      </div>
    )
  }
}
