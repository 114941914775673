/**
 * @prettier
 */
export class BaseTextLabel extends React.PureComponent {
  render() {
    const { className, kind, children } = this.props
    const fieldClassName = classNames('base-text-label', className, {
      [`is-${kind}`]: kind,
    })

    return (
      <div className={fieldClassName}>
        <mark>{children}</mark>
      </div>
    )
  }
}
