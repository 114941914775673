/**
 * @prettier
 */

import { observer } from 'mobx-react'
import styled from 'styled-components'

import { StaticWidget } from 'components/shared/static_widget/static_widget'
import { StaticWidgetStore } from 'stores/models/static_widget/static_widget_store'

const StaticWidgetWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 5px;
`

export const CampaignFilterCampaignStaticWidget = observer(
  class CampaignFilterCampaignStaticWidget extends React.PureComponent {
    static propTypes = {
      campaign: PT.object.isRequired,
      siteCachedSlug: PT.string.isRequired,
      editMode: PT.bool,
    }

    static defaultProps = {
      editMode: false,
      campaign: {
        id: null,
        appearance: null,
        status: '',
      },
    }

    constructor(props) {
      super(props)
      this.staticWidgetStore =
        props.campaign.static_widget === '1' &&
        StaticWidgetStore.create({
          id: `${props.siteCachedSlug}_${props.campaign.id}`,
          campaign_id: props.campaign.id,
          site_cached_slug: props.siteCachedSlug,
          disabled_state: props.is_edit && true,
          tooltipDescription:
            props.is_edit && 'This campaign uses “Static trigger widget” setting',
        })
    }

    render() {
      const { campaign, editMode } = this.props

      return (
        campaign.static_widget === '1' && (
          <StaticWidgetWrapper>
            <StaticWidget
              size="small"
              background="#fff"
              polling={campaign.status === 'Live' && !editMode}
              staticWidgetStore={this.staticWidgetStore}
            />
          </StaticWidgetWrapper>
        )
      )
    }
  },
)
