/**
 * @prettier
 */

import styled from 'styled-components'
import { UIInputSelect } from 'talkable-ui-kit'
import { observer } from 'mobx-react'

const SelectWrapper = styled.div`
  z-index: 1;
  width: auto;
  min-width: 150px;
`

export const CampaignsFilterSelect = observer(
  class CampaignsFilterSelect extends React.PureComponent {
    static propTypes = {
      value: PT.string.isRequired,
      options: PT.arrayOf(PT.object).isRequired,
      onChange: PT.func.isRequired,
      size: PT.oneOf(['big', 'default']),
    }

    static defaultProps = {
      size: 'big',
    }

    render() {
      const { value, options, onChange, name, size } = this.props

      return (
        <SelectWrapper className="mls">
          <UIInputSelect
            value={value}
            name={name}
            options={options}
            size={size}
            onChange={onChange}
          />
        </SelectWrapper>
      )
    }
  },
)
