/**
 * @prettier
 */
import styled, { css } from 'styled-components'
import { BodyM, Theme, UISpoiler } from 'talkable-ui-kit'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  ${({ divider }) =>
    divider &&
    css`
      border-bottom: 1px solid #e5e5e5;
    `}
`
Row.propTypes = {
  divider: PT.bool,
}
Row.defaultProps = {
  divider: true,
}

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const Cell = styled.div`
  box-sizing: border-box;
  padding-right: 16px;
  flex-shrink: 0;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${({ stretch }) =>
    stretch &&
    css`
      flex-grow: 1;
    `};
  ${({ shrink }) =>
    shrink &&
    css`
      flex-shrink: 1;
    `};
  &:last-child {
    padding-right: 0;
  }
`

Cell.defaultProps = {
  shrink: false,
}

export const Spoiler = ({ children, title, defaultShow, dataTestId }) => {
  return (
    <UISpoiler
      defaultShow={defaultShow}
      text={title}
      styleTitle={{ color: Theme.color.link }}
      style={{ width: '100%' }}
      data-testid={dataTestId}
    >
      {children}
    </UISpoiler>
  )
}

Spoiler.propTypes = {
  dataTestId: PT.string,
  title: PT.string.isRequired,
  defaultShow: PT.bool,
}

Spoiler.defaultProps = {
  defaultShow: true,
}

export const Empty = ({ children, selectedCampaignsCount }) => {
  return (
    <BodyM className="mtl" color={Theme.color.black50}>
      {children || selectedCampaignsCount
        ? `No campaigns found, but ${selectedCampaignsCount} is selected`
        : 'No campaigns found'}
    </BodyM>
  )
}

export const FilteredHiddenCampaignsNote = ({ children, hiddenCampaignsNote }) => {
  return (
    <BodyM className="mtl" color={Theme.color.black50}>
      {children || hiddenCampaignsNote}
    </BodyM>
  )
}

export const Head = Row
