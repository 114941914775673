/**
 * @prettier
 */

import styled, { css } from 'styled-components'
import { BodyS, Theme } from 'talkable-ui-kit'
import { observer } from 'mobx-react'

import { Cell } from './index'

const CellStyleOverride = styled(Cell)`
  width: ${({ width }) => width};
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`

export const CampaignsFilerOffersCell = observer(
  class CampaignsFilerOffersCell extends React.PureComponent {
    static propTypes = {
      width: PT.string,
      campaign: PT.object.isRequired,
      align: PT.oneOf(['left', 'right', 'center']),
    }

    static defaultProps = {
      width: '70px',
      align: 'left',
    }

    render() {
      const {
        width,
        align,
        campaign: { formattedOffersCountWithLimit },
      } = this.props

      return (
        <CellStyleOverride width={width} align={align}>
          <BodyS color={Theme.color.black100}>{formattedOffersCountWithLimit}</BodyS>
        </CellStyleOverride>
      )
    }
  },
)
