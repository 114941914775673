/**
 * @prettier
 */

import { observer } from 'mobx-react'
import { UICampaignBadge, UICopy, BodyD, H5 } from 'talkable-ui-kit'
import styled, { css } from 'styled-components'

import { getUrlSpaceParameterBySiteSpace } from 'utils/etc'

import { Cell } from './index'

const CampaignStatus = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: border-box;
  ${props => {
    if (props.status === 'Live') {
      return css`
        background: #3c913c;
      `
    }
    if (props.status === 'Disabled') {
      return css`
        width: 10px;
        height: 10px;
        border-radius: 0;
        background: #df4343;
      `
    }
    if (props.status === 'Scheduled') {
      return css`
        display: inline-block;
        width: 4px;
        border-radius: 0;
        background: #eaa717;
        margin-right: 8px;
        &::after {
          content: '';
          display: block;
          margin-left: 2px;
          width: 4px;
          height: 12px;
          background-color: #eaa717;
          right: -5px;
          position: relative;
        }
      `
    }

    return css`
      border: 2px solid #eaa717;
    `
  }}
`
const Name = styled.a`
  display: inline-block;
  line-height: 21px;
  max-width: 500px;
  overflow: hidden;
  word-break: break-all;
`
const InfoCampaign = styled.div`
  height: 19px;
`

const WebhookUrlContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const WebhookUrl = styled.span`
  white-space: ${props => (props.showFullUrl ? 'normal' : 'nowrap')};
  overflow: ${props => (props.showFullUrl ? 'visible' : 'hidden')};
  text-overflow: ${props => (props.showFullUrl ? 'clip' : 'ellipsis')};
  max-width: 200px;
  word-wrap: break-word;
  margin-right: 10px;
`

const ClickableSpan = styled.span`
  cursor: pointer;
`

export const CampaignsFilterMainInfoCell = observer(
  class CampaignsFilterMainInfoCell extends React.PureComponent {
    static propTypes = {
      siteCachedSlug: PT.string.isRequired,
      campaign: PT.object.isRequired,
      stretch: PT.bool,
      notice: PT.string,
      withDate: PT.bool,
      hideCampaignLink: PT.bool,
      appStore: PT.bool,
    }

    static defaultProps = {
      direction: '',
      stretch: true,
      shrink: true,
      notice: '',
      withDate: false,
      hideCampaignLink: false,
      appStore: false,
    }

    state = {
      showFullUrl: false,
    }

    handleCopyClick = () => {
      if (this.state.showFullUrl) return
      this.setState({ showFullUrl: true })

      setTimeout(() => {
        this.setState({ showFullUrl: false })
      }, 2000)
    }

    render() {
      const {
        getSheerIdWebhook,
        siteCachedSlug,
        campaign: {
          status,
          placement_category_abbreviation,
          name,
          id,
          latest_change,
          space,
          sheerid_program_id,
        },
        children,
        stretch,
        notice,
        shrink,
        className,
        withDate,
        hideCampaignLink,
        appStore,
      } = this.props

      const { showFullUrl } = this.state

      return (
        <Cell stretch={stretch} shrink={shrink} className={`flex ${className}`}>
          <div className="flex align-items-flex-start">
            <InfoCampaign className="flex align-items-center">
              <CampaignStatus status={status} />
              <UICampaignBadge
                type="all"
                category={placement_category_abbreviation}
                size="mini"
                className="mls"
              />
            </InfoCampaign>
            <div className="mls">
              <div className="flex align-items-center">
                {hideCampaignLink ? (
                  <H5>{name}</H5>
                ) : (
                  <Name
                    href={Routes.site_campaign_path(
                      siteCachedSlug,
                      getUrlSpaceParameterBySiteSpace(space),
                      id,
                    )}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="link campaigns-filter-campaign-link"
                  >
                    {name}
                  </Name>
                )}

                {children}
              </div>
              <BodyD>
                {withDate && `${latest_change?.formattedCreatedAtWithoutTime} • `}
                ID: {id}
                {notice && ` • ${notice}`}
                {sheerid_program_id && appStore && (
                  <WebhookUrlContainer>
                    <WebhookUrl showFullUrl={showFullUrl}>
                      <span>Webhook URL: </span>
                      <ClickableSpan onClick={this.handleCopyClick}>
                        {getSheerIdWebhook(id)}
                      </ClickableSpan>
                    </WebhookUrl>
                    <UICopy value={getSheerIdWebhook(id)} />
                    <span
                      className="mlx"
                      aria-label="Use this Webhook URL for both 'Eligible verification URL' and 'Ineligible verification URL' settings in SheerID Program Account Linking"
                      data-balloon-pos="up"
                      data-balloon-length="large"
                    >
                      <i className="icon icon-question">
                        <span>&#64;</span>
                      </i>
                    </span>
                  </WebhookUrlContainer>
                )}
              </BodyD>
            </div>
          </div>
        </Cell>
      )
    }
  },
)
