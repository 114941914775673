/**
 * @prettier
 */

import { nanoid } from 'nanoid'
import { UIInputCheckbox } from 'talkable-ui-kit'

import { Cell } from './index'

export class CampaignsFilterCheckboxCell extends React.PureComponent {
  static propTypes = {
    checked: PT.oneOf([true, false, null]),
    disabled: PT.bool,
    onChange: PT.func,
  }

  static defaultProps = {
    disabled: false,
    onChange: () => {},
  }

  constructor(props) {
    super(props)
    this.id = nanoid()
  }

  render() {
    const { checked, disabled, onChange } = this.props

    return (
      <Cell>
        <div className="flex mls">
          <UIInputCheckbox
            indeterminate={checked === null || undefined}
            id={this.id}
            checked={checked || false}
            disabled={disabled}
            name={this.id}
            onChange={onChange}
            data-testid="ac-campaign-migrate-tool-campaign-available-checkbox"
          />
        </div>
      </Cell>
    )
  }
}
