/**
 * @prettier
 */

import styled, { css } from 'styled-components'
import { UIButton, UIIcon, Theme } from 'talkable-ui-kit'

const ButtonStyleOverride = styled(UIButton)`
  ${({ isOpened }) =>
    isOpened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};
  transition: color 0.275s;
  &:hover {
    .svg-path-fill {
      fill: ${Theme.color.orange};
    }
  }
`

export class CampaignsFilterShowMore extends React.PureComponent {
  static propTypes = {
    onClick: PT.func.isRequired,
    hiddenCampaignsCount: PT.number.isRequired,
    isOpened: PT.bool.isRequired,
  }

  render() {
    const { onClick, hiddenCampaignsCount, isOpened } = this.props

    if (!isOpened && hiddenCampaignsCount === 0) {
      return null
    }

    return (
      <div className="flex justify-center align-items-center mtm">
        <ButtonStyleOverride
          data-testid="ac-campaigns-filter-show-more-button"
          onClick={onClick}
          trait="action"
          isOpened={isOpened}
        >
          {isOpened ? 'Hide' : <>Show {hiddenCampaignsCount} campaigns</>}
          <UIIcon className="mls" name="downArrow" />
        </ButtonStyleOverride>
      </div>
    )
  }
}
