/**
 * @prettier
 */

import { BaseTextLabel } from 'legacy/widget/app/components/base_text_label'

export class BaseTextLabelStatus extends React.PureComponent {
  static propTypes = {
    status: PT.oneOf(['ready', 'inactive', 'active', 'finished']).isRequired,
    kind: PT.oneOf(['short', 'long']),
  }

  static defaultProps = {
    type: 'ready',
    kind: 'long',
  }

  getStatusDescription = ({ status, kind }) => {
    if (status === 'ready') {
      return kind === 'long' ? (
        'Ready to run'
      ) : (
        <span>
          Ready <br /> to run
        </span>
      )
    } else {
      return `${status.charAt(0).toUpperCase() + status.slice(1)}`
    }
  }

  render() {
    const { status, kind, className } = this.props

    const fieldClassName = classNames(className, {
      'is-warning is-text-left': status === 'ready',
      'is-error': status === 'active',
      'is-success': status === 'finished',
    })

    return (
      <BaseTextLabel className={fieldClassName} kind={kind}>
        {this.getStatusDescription({ status, kind })}
      </BaseTextLabel>
    )
  }
}
